.productImage {
  position: relative;
  width: 100%;
  /* Responsive width */
  padding-top: 80%;
  /* Aspect ratio -> Height / Width = 4 / 5 = 0.8 = 80% */
  overflow: hidden;
  /* Ensures no overflow of content */
  box-shadow: 0px 4px 8px rgba(60, 74, 73, 0.5);
  border-radius: 8px;
  cursor: pointer;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* Full width of the container */
    height: 100%;
    /* Full height of the container */
    object-fit: cover;
    /* This ensures the image covers the full container */
  }
}

.input {
  height: 40px;
  border: 1px solid #bac5c5;
  width: 100%;
  border-radius: 8px;
  margin-top: 8px;
  padding: 6px 10px 6px 10px;
}

.select {
  .dropdown {
    overflow-y: hidden;
    background-color: white;
    height: 40px;
    border: 1px solid #bac5c5;
    width: 100%;
    border-radius: 8px;

    p {
      border-radius: 4px;
      color: gray;
      font-weight: 500;
      font-size: 1rem;
      letter-spacing: -0.5px;
      text-transform: capitalize;
      max-width: 188.6px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 10px;
    }

    span {
      font-size: 1rem;
      color: #c1c1c1;
      text-transform: none !important;
      font-weight: 400;
    }
  }

  .menu {
    width: 100%;
    padding: 6px 8px 16px 16px;
    box-shadow: 0px 4px 14px rgba(60, 72, 72, 0.1);
    border: 1px solid #efefef;
    border-radius: 8px;
    max-height: 400px;
    overflow-y: auto;

    h4 {
      font-weight: 700;
      font-size: 20px;
      color: #212e2b;
      margin-top: 8px;
    }

    p {
      margin-bottom: 0px;
      font-weight: 400;
      font-size: 16px;
      color: #212e2b;
      padding: 6px 0px;
      cursor: pointer;
      margin-top: 6px;
    }

    p.parent {
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 600;
      color: #0b6568;
      width: 100%;
      border-bottom: 1px solid #dadada;
      padding: 0px;
      cursor: unset;
      margin-top: 10px;
    }
  }
}
