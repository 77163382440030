.itemDonationsCard {
  .itemDonationContent {
    display: flex;
    align-items: center;

    img.productImage {
      height: 80px;
      width: 80px;
      object-fit: fill;
      border-radius: 100%;
      box-shadow: 1px 2px 6px #dadada;
    }

    @media (max-width: 800px) {
      flex-direction: column;
      width: 100%;

      img.productImage {
        height: 140px;
        width: 100%;
        object-fit: cover;
        border-radius: 4px;
      }
    }
  }

  .productBody {
    display: flex;
    flex: 1;
    align-items: center;
    margin-left: 20px;
    justify-content: space-between;
    color: #777777 !important;

    .productTitleContainer {
      flex: 0.15;

      .productTitle {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #000000 !important;
        height: 18px;
        text-transform: uppercase;
        white-space: nowrap;
        max-width: 160px;
        overflow: hidden;
        letter-spacing: -0.4px;
        text-overflow: ellipsis;
      }

      .productQuantity {
        font-size: 14px;
        opacity: 0.6;
      }
    }

    .productMeta1 {
      flex: 0.1;
      display: flex;
      justify-content: space-between;
      display: none;
    }

    .productMeta2 {
      flex: 0.15;

      p {
        margin-bottom: 0px;
      }
    }

    .productMeta3 {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .productMeta4 {
      flex: 0.1;
    }

    .description {
      flex: 0.1;
      width: 100px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;

      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 50px;
    }

    .tags {
      display: flex;
      flex: 0.3;

      & > div {
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        align-items: baseline;
        height: fit-content !important;

        & > div {
          color: #28a745;
          text-transform: uppercase;
          font-size: 10px;
          font-weight: 700;
          background: #28a74529;
          padding: 5px 5px 3px 5px;
          border: 1.5px solid #28a745;
          border-radius: 15px;
          overflow: hidden;
          white-space: nowrap;
          letter-spacing: -0.2px;
          margin: 2px;
        }

        .category {
          color: #dc3545 !important;
          border-color: #dc3545 !important;
          background: #dc35451a !important;
        }
      }
    }

    .actions {
      display: flex;
      justify-content: space-around;
      flex: 0.1;

      img {
        cursor: pointer;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      }

      height: 25px;
    }

    @media (max-width: 800px) {
      flex-direction: column;
      width: 100%;
      margin-left: 0px;
      margin-top: 5px;

      .productTitleContainer,
      .productMeta1,
      .productMeta2,
      .productMeta3,
      .productMeta4,
      .description,
      .tags,
      .actions {
        flex: 1 !important;
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 4px;
      }

      .productMeta2 {
        flex-direction: column;
        font-size: 12px;
      }

      .description {
        justify-content: flex-start;
      }

      .tags {
        flex-direction: column;

        div.donatetagRowMobileWidth {
          width: 100% !important;
          padding: 5px 0px;
        }
      }

      .actions {
        margin-top: 5px;
      }
    }

    .reject {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-align: center;

      color: #fe7f7f;
      height: 25px;
    }

    .approve {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-align: center;

      color: #63d08f;
      height: 25px;
    }
  }
}

.rejectWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #c1c1c198;
  z-index: 9999;

  .rejectBox {
    margin: 150px auto 0 auto;
    width: 100%;
    max-width: 600px;
    padding: 50px;
    background-color: #ffffff;
    position: relative;

    .x {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }

    .label {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      display: block;
      color: #000000;
      margin-bottom: 0;
      margin-top: 5px;
    }

    .row {
      margin-top: 20px;
      display: flex;
      justify-content: space-around;

      .reject {
        cursor: pointer;
        margin-top: 10px;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-align: center;

        color: #fe7f7f;
        height: 25px;
      }
    }
  }
}

.nextButtonWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  button {
    background: #ffc1c1;
    border-radius: 2px;
    width: 150px;
    padding: 10px 30px;
    color: white;
    border: none;
    outline: none;
  }
}

.donateItemEdit {
  background: #ffffff;
  padding: 29px;
  position: relative;

  .x {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .label {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    display: block;
    color: #000000;
    margin-bottom: 0;
    margin-top: 10px;
  }

  .input,
  .textarea {
    box-sizing: border-box;
    border-radius: 3px;
    padding: 10px;
    width: 100%;
  }

  .dropdown {
    border: 1px solid #a3a3a3 !important;
    width: 100%;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 7px;
    display: flex;
    justify-content: space-between;
    background-color: #ffffff !important;
    color: black !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;

    .category {
      margin-right: 5px;
      padding: 3px 5px;
      background: #ffc1c1;
      width: fit-content;
    }

    .tag {
      margin-right: 5px;
      width: fit-content;
      padding: 3px 5px;
      background: #fff7b2;
    }
  }

  .tags {
    width: 100%;
  }
}

.searchInput {
  width: min(350px, 100%);
  background: white;
  height: 45px;
  border: 1px solid #eee;
  padding-left: 10px;
  border-radius: 6px;
  font-size: 1.01rem;
  box-shadow: 1px 1px 2px #efeeeeea;
}

.categoryTag {
  padding: 1px 3px;
  background: #ffc1c1;
  text-align: center;
  width: fit-content;
  margin: 1px 1px 1px 0px;
  font-size: 12px;
  border-radius: 4px;
  text-transform: capitalize;
  letter-spacing: -0.5px;
}

.searchInput {
  background: transparent;
  border: none;
  border-bottom: 1px solid #dadada;
  width: 100%;
  font-size: 16px;
  margin-left: 6px;
  padding: 5px 5px;

  @media (max-width: 1000px) {
    font-size: 14px;
  }
}

.searchInput:focus {
  border-bottom-width: 2px;
  outline: none;
}
